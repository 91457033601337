/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

:host body{
	font-family: 'Poppins', sans-serif;
  background-color: #f9f9f9;
}

:host {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 8px 0;
}

p {
  margin: 0;
}

.spacer {
  flex: 1;
}

.toolbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #1976d2;
  color: white;
  font-weight: 600;
}

.toolbar img {
  margin: 0 16px;
}

.toolbar #twitter-logo {
  height: 40px;
  margin: 0 8px;
}

.toolbar #youtube-logo {
  height: 40px;
  margin: 0 16px;
}

.toolbar #twitter-logo:hover,
.toolbar #youtube-logo:hover {
  opacity: 0.8;
}

.content {
  display: flex;
  margin: 82px auto 32px;
  padding: 0 16px;
  max-width: 960px;
  flex-direction: column;
  align-items: center;
}

svg.material-icons {
  height: 24px;
  width: auto;
}

svg.material-icons:not(:last-child) {
  margin-right: 8px;
}

.card svg.material-icons path {
  fill: #888;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
}

/* .card {
  all: unset;
  border-radius: 4px;
  border: 1px solid #eee;
  background-color: #fafafa;
  height: 40px;
  width: 200px;
  margin: 0 8px 16px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  line-height: 24px;
} */

.card-container .card:not(:last-child) {
  margin-right: 0;
}

.card.card-small {
  height: 16px;
  width: 168px;
}

.card-container .card:not(.highlight-card) {
  cursor: pointer;
}

.card-container .card:not(.highlight-card):hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
}

.card-container .card:not(.highlight-card):hover .material-icons path {
  fill: rgb(105, 103, 103);
}

.card.highlight-card {
  background-color: #1976d2;
  color: white;
  font-weight: 600;
  border: none;
  width: auto;
  min-width: 30%;
  position: relative;
}

.card.card.highlight-card span {
  margin-left: 60px;
}

svg#rocket {
  width: 80px;
  position: absolute;
  left: -10px;
  top: -24px;
}

svg#rocket-smoke {
  height: calc(100vh - 95px);
  position: absolute;
  top: 10px;
  right: 180px;
  z-index: -10;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

a:hover {
  color: #125699;
}
a:active{
  color:#e8601b;
}

.terminal {
  position: relative;
  width: 80%;
  max-width: 600px;
  border-radius: 6px;
  padding-top: 45px;
  margin-top: 8px;
  overflow: hidden;
  background-color: rgb(15, 15, 16);
}

.terminal::before {
  content: "\2022 \2022 \2022";
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  background: rgb(58, 58, 58);
  color: #c2c3c4;
  width: 100%;
  font-size: 2rem;
  line-height: 0;
  padding: 14px 0;
  text-indent: 4px;
}

.terminal pre {
  font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
  color: white;
  padding: 0 1rem 1rem;
  margin: 0;
}

.circle-link {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  margin: 8px;
  background-color: white;
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 1s ease-out;
}

.circle-link:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

/* footer {
  margin-top: 8px;
  display: flex;
  align-items: center;
  line-height: 20px;
} */

/* footer a {
  display: flex;
  align-items: center;
} */

.github-star-badge {
  color: #24292e;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 3px 10px;
  border: 1px solid rgba(27,31,35,.2);
  border-radius: 3px;
  background-image: linear-gradient(-180deg,#fafbfc,#eff3f6 90%);
  margin-left: 4px;
  font-weight: 600;
}

.github-star-badge:hover {
  background-image: linear-gradient(-180deg,#f0f3f6,#e6ebf1 90%);
  border-color: rgba(27,31,35,.35);
  background-position: -.5em;
}

.github-star-badge .material-icons {
  height: 16px;
  width: 16px;
  margin-right: 4px;
}

svg#clouds {
  position: fixed;
  bottom: -160px;
  left: -230px;
  z-index: -10;
  width: 1920px;
}

/* Responsive Styles */
@media screen and (max-width: 767px) {
  .card-container > *:not(.circle-link) ,
  .terminal {
    width: 100%;
  }

  .card:not(.highlight-card) {
    /* height: 16px; */
    margin: 8px 0;
  }

  .card.highlight-card span {
    margin-left: 72px;
  }

  svg#rocket-smoke {
    right: 120px;
    transform: rotate(-5deg);
  }
}

@media screen and (max-width: 575px) {
  svg#rocket-smoke {
    display: none;
    visibility: hidden;
  }
}
.imagen{

width: 100vw; 
height: auto;
display: flex; 
position: fixed; 
top: 0;
left: 0; 
z-index: -1; 
}
.me-2 {
margin-right: 1.2rem!important;
}

.mat-horizontal-stepper-header-container .ng-tns-c3676410898-4{
  display: none !important;
}

html, body { height: 100%; }
/* body { margin: 0; font-family: Raleway, "Raleway", sans-serif; } */
body{
  font-family: 'Poppins', sans-serif;
  background-color: #f9f9f9;
}
.mat-horizontal-stepper-header-container {
    white-space: nowrap;
    display: flex;
    align-items: center;
    visibility: hidden;
    height: 30px;
}
.mat-horizontal-stepper-header {
    display: flex;
    height: 5px;
    overflow: hidden;
    align-items: center;
    padding: 0 24px;
}
.mat-horizontal-stepper-wrapper{
  
   border: 1px;
   border-color: #2a20b36d;
   border-radius: 10px;
}
@media screen and (min-width: 650px) {
    .navbar-toggler-open:not(.always-shown) {
      display: none;
    }
  }
  @media screen and (max-width: 770px) {
    #colR {
      visibility: collapse;
      height: 0px;
    }
  }
  @media screen and (max-width: 770px) {
    .navbar-toggler-menu {
      visibility: collapse;
    }
  }
  .navbar-toggler-close, .navbar-toggler-open {
    vertical-align: middle;
    cursor: pointer;
    color: currentColor;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
  }
  
  .navbar-toggler-open {
    display: flex;
  }
  
  .navbar-toggler-close, .navbar-toggler-open {
    vertical-align: middle;
    cursor: pointer;
    color: currentColor;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 768px) {
    .navbar-nav {
      flex-direction: column;
      margin-left: 0rem;
    }
    .sliderTeacher{
      margin-top: 10px;
    }
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
    .bg-hide-col{
      visibility: collapse;
      margin: 0;
      padding: 0;
      display: contents;
  }
  .textMod{
    font-size: 1.5rem;
    color: #000;
    width: fit-content;
  }
  .btnMod{
    font-size: small;
    flex-direction: column;
    text-wrap: nowrap;
    width: fit-content;
  }
  .adapCarrusel-s{
    font-size: 11px;
  }
  .btnAlign {
    flex-direction: column;
    align-items: center;
  }
}

  .dropdown-menu :active{ background-color:  transparent;}

  @media (min-width: 1024px) {
    .cntrlColor {
      background-color: transparent;
    }

    .textModBig{
      font-size: 2.5rem;
      color: #fff;
    }

    .adapCarrusel{
      margin: 3rem;
      font-size: 18px;
      padding: 10px 0 10px 0px;
      font-size: 18px;      
    }
  }
  .btn-primary{
    background-color: #e8601b;
    width: 100%;
    border: none;
    border-radius: 30px;
    padding: 10px 0 10px 0px;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
  }

  .btn-primary2{
    background-color: #e8601b;
    width: 100%;
    border: none;
    border-radius: 30px;
    font-weight: bold;
  }
  
  a {
  
    text-align: center;
    text-decoration: none; 
    color: #d85c24;
  }

  .gradient-bg {
    background-color: #032f57;
  }
  .mb-3 {
    margin-bottom: 1rem!important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0px;
  }
  
  .icon{
    position: absolute;
    left: 23px;
    top: 0;
    font-size: 25px;
    color: #2a3341;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input.form-control{
    padding: 10px 0px 10px 50px;
    font-size: 17px;
    line-height: 30px;
    display: block;
    width: 110%;
    outline: 0;
    border-radius: 30px;
    color: #2a3341;
    
  }
  .itemAdmision{
    margin:auto;
  }
  

  /* .card-img {
    width: 50%;
    height: 50%;
    border-radius: 10px;
  } */
  .modal-header{
    border-bottom: none;
  }
  .modal-footer {
    border-top: none;
  }
  
  .modal-header .btn-close {
    color: #f0f3f6;
  }

  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu li {
    display: inline-block;
    padding: 5px;
  }
  
  .menu li:hover {
    border-bottom: 3px solid #F47B20;
    transition: border-bottom 0,5s ease-in-out;
  }
  
  .dropdown:hover .dropdown-menu {
    display: block;
  }

  .cardEfect:hover {
    border-right: 10px solid #F09A00;
    background-color: #fff;
  }
  .elColorCard {
    background-color: #f3f3f3;
  }
  @media print{
    .font-print{
      font-size: 10px;
      font-family: Arial, Helvetica, sans-serif;
      color: #000;
      background-color: transparent;
      
    }
    .bg-gris{
      background-color: #656a6e;
      background-color: transparent;
    }
    .btn-no{
      visibility: hidden;
    }
  }

.iniceNav{
  border: 0;
  background-color: #0E4C75;
}

.iniceNavEfec:hover{
  border-bottom: 3px solid #fff;
  transition: border-bottom 0,5s ease-in-out;
  margin-top: 10px;
}

.backColor{
  background-color: #0E4C75;
}

.btn-inv{
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #818C96;
  background-color: #F1F1F5;
  border-color: #818C96;
}

.btn-invEfec:hover{
  background-color: #F09A00;
  color: #fff;
  border-color: #F09A00;
}

.rem-6{
  font-size: 0.6rem;
}
.rem-7{
  font-size: 0.7rem;
}
.rem-8{
  font-size: 0.8rem;
}
.rem-9{
  font-size: 0.9rem;
}
.avatar {
  align-items: center;
  border-radius: 0.375rem;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 600;
  height: 2.875rem;
  justify-content: center;
  position: relative;
  vertical-align: middle;
  width: 2.875rem;
}

